<template>
  <v-main class="">
    <section>
      <div class="container">
        <div class="row">
          <div class="col col-12">
            <h6 style="font-size: 16px">Газраа сонгоод аялалын төлөвлөгөөндөө оруулаx</h6>
          </div>
          <TripCardSimple
            class="col-sm-6 col-md-4 col-lg-3 col-12 mr-8"
            v-for="location in savedLocations"
            :key="location.id"
            :tripdata="$attrs.tripdata"
            :location="location"
            v-bind="$attrs"
            :planned="$attrs.planned"
          >
          </TripCardSimple>
        </div>
      </div>
    </section>
  </v-main>
</template>
<script>
const fb = require('@/firebaseConfig.js')
import TripCardSimple from "./TripCardSimple.vue";
export default {
  components: {
    TripCardSimple,
  },
  data() {
    return {
 
      editImage: false,
      name: null,
      price: null,
      savedLocations: null
    };
  },
  methods: {
    _goDetail(id) {
      this.$router.push({
        name: "RestaurantDetail",
        params: { restaurantId: id },
      });
    },
  },
  created(){
    fb.db.collection(this.$attrs.tripRef+"/saved").onSnapshot((docs)=>{
      this.savedLocations = []
      docs.forEach(element => {
        let location = element.data()
        location.id = element.id 
        location.ref = element.ref
        this.savedLocations.push(location)
      });
    })
  }
   
};
</script>
<style>
.p-absolute {
  position: absolute !important;
}
.top-0 {
  top: 0 !important;
}
.text-18 {
  font-size: 20px !important;
}

.f-600 {
  font-weight: 400;
}
.text-14,
h6 {
  font-size: 14px;
}
</style>